<footer class="footer pb-0" *transloco="let t">
  <div class="container-fluid">
    <div class="row">
      <div class="col-4">
        <div class="col-lg-12 text-center"><img src="assets/img/logo/iSophi_podpora_pedagoga.svg" alt="iSophi PAP logo" /></div>
      </div>
      <div class="col-4 mt-3">
        <p class="mb-1">{{ t('shared/quote') }}</p>
        <p class="font-weight-bold">{{ t('shared/quoteAuther') }}</p>
      </div>
      <div class="col-4 mt-3">
        <p>
          <span class="font-weight-bold">{{ t('shared/stayInTouch') }}</span
          ><br />
          <a href="mailto:info@podporapedagoga.cz" rel="noopener noreferrer" target="_blank">info@podporapedagoga.cz</a>
        </p>
      </div>
    </div>
    <div class="row align-items-center border-top p-4">
      <div class="col-md-12 col-sm-12 mt-3 mt-lg-0 text-center">
        <small class="d-block text-muted"> &copy; 2022 STEP, {{ t('shared/version') }} {{ version }} {{ envShortcut }} </small>
      </div>
    </div>
  </div>
</footer>
