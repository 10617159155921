import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MoreInfoFormComponent } from '@app/shared/components/more-info-form/more-info-form.component';
import { PopUpComponent } from '@app/shared/components/pop-up/pop-up.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { Child, Contract, Gender785Enum, ParentAdditionalInfoService } from '@parents-api';
import { AlertType, DateHelper } from 'isophi-core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, finalize } from 'rxjs';
import { v4 as uuid } from 'uuid';

import { RouterLinkFactory } from '../router/router-link.factory';
import { ChildService } from './child.service';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  titleChanged$: BehaviorSubject<string> = new BehaviorSubject(null);

  private child: Child;

  constructor(
    private childService: ChildService,
    private spinner: NgxSpinnerService,
    private popUp: PopUpComponent,
    private i18n: TranslocoService,
    private links: RouterLinkFactory,
    private router: Router,
    private parentAdditionalInfoService: ParentAdditionalInfoService,
    private modal: NgbModal
  ) {}

  setTitle(customTitle?: string): void {
    const title = customTitle ? customTitle : '';
    this.titleChanged$.next(title);
  }

  // Formate selection data
  prepareData(dataObject: { [key: string]: string }, enumObject): { [key: string]: string }[] {
    const data = [];
    for (const val in enumObject) {
      const value = enumObject[val];
      data.push({ text: dataObject[val], value });
    }
    return data;
  }

  addNewFakeChildAndFillQuestionnaire(): void {
    this.spinner.show();

    const fakeChild = {
      firstName: 'fakeChild4SET',
      lastName: this.i18n.getActiveLang(),
      gender: Gender785Enum.Male
    } as Child;
    const fakeContract = {
      majorVersion: '2',
      minorVersion: '0'
    } as unknown as Contract;

    fakeChild.uuid = uuid();
    fakeChild.firstName = fakeChild.birthDate = DateHelper.toStringDateOnly(new Date());

    this.childService.createChild(fakeChild, fakeContract, true).subscribe({
      next: (child) => {
        this.child = child;
        this.spinner.hide();
        this.router.navigate(this.links.fillQuestionnaire(child));
      },
      error: (reason) => {
        this.spinner.hide();
        const errorData = reason.error;

        if (errorData.code !== 1001) {
          this.popUp.alert('Error - please try again later', AlertType.WARNING);
          return;
        }
      }
    });
  }

  deleteChild(): void {
    this.childService.deleteChild(this.child.uuid).subscribe();
  }

  checkAdditionalInfo(): void {
    this.spinner.show();
    this.parentAdditionalInfoService
      .parentAdditionalInfoCheckAdditionalInfoRetrieve()
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe((val) => {
        if (val) {
          this.addNewFakeChildAndFillQuestionnaire();
        } else {
          this.modal.open(MoreInfoFormComponent, { size: 'lg', centered: true });
        }
      });
  }
}
