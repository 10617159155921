<ng-container *transloco="let t">
  <li class="slide">
    <a class="side-menu__item" [routerLink]="routerLinkFactory.about()" routerLinkActive="active">
      <i class="fe fe-info sidemenu_icon"></i>
      <span class="side-menu__label">{{ t('shared/about') }}</span>
    </a>
  </li>

  <li class="slide">
    <a
      class="side-menu__item"
      [class.disabled]="children?.length <= 0"
      [routerLink]="routerLinkFactory.result(selectedChild)"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <img class="sidemenu_icon" src="assets/img/iconsDefault/leaderboard.svg" alt="leaderboard" />
      <span class="side-menu__label">{{ t('result/overview') }}</span>
    </a>
  </li>

  <li class="slide">
    <a
      class="side-menu__item"
      [class.disabled]="!(isChildSelected && hasSelectedChildResults)"
      [routerLink]="isChildSelected && hasSelectedChildResults ? routerLinkFactory.recommendation(selectedChild) : null"
      routerLinkActive="active"
    >
      <i class="fe fe-target sidemenu_icon"></i>
      <span class="side-menu__label">{{ t('shared/supportAreas') }}</span>
    </a>
  </li>

  <li class="slide">
    <a
      class="side-menu__item"
      [class.disabled]="!(isChildSelected && hasSelectedChildResults)"
      [routerLink]="isChildSelected && hasSelectedChildResults ? routerLinkFactory.participation() : null"
      routerLinkActive="active"
    >
      <i class="fe fe-award sidemenu_icon"></i>
      <span class="side-menu__label">{{ t('info/participation') }}</span>
    </a>
  </li>
</ng-container>
