import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLinkFactory } from '@app/core/router/router-link.factory';
import { AccountService } from '@app/core/services/account.service';
import { ChildService } from '@app/core/services/child.service';
import { LicenseService } from '@app/core/services/license.service';
import { EditLicenseComponent } from '@app/modules/user/components/edit-license/edit-license.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Child, LastResults } from '@parents-api';
import { AuthService } from 'isophi-core';
import { combineLatest, Observable, switchMap } from 'rxjs';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent {
  licenses$: Observable<{ premium: boolean }>;

  children$: Observable<Child[]>;

  selectedChild$: Observable<Child>;

  results$: Observable<LastResults>;

  public constructor(
    public routerLinkFactory: RouterLinkFactory,
    private modalService: NgbModal,
    private childService: ChildService,
    private licenseService: LicenseService,
    private accountService: AccountService,
    private authService: AuthService
  ) {
    this.licenses$ = combineLatest({
      premium: this.licenseService.hasPremium()
    });
    this.children$ = this.childService.additionalInfo$.pipe(switchMap(() => this.childService.getChildren()));
    this.selectedChild$ = this.childService.getSelectedChild();
    this.results$ = this.childService.getSelectedChildResults();
  }

  get username(): string {
    return this.authService.loggedUser?.email;
  }

  onActivationCode() {
    this.modalService.open(EditLicenseComponent);
  }

  onLogout() {
    this.accountService.logout();
  }
}
