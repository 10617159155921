// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { commonEnvironment } from '@env/environment.common';

import { LoggingLevel } from './log-level.enum';

export const environment = {
  envShortcut: 'E',
  production: false,
  logLevel: LoggingLevel.DEBUG,
  common: commonEnvironment,
  client_id: 'd3ZUUPYOHHOvOC4KYSeyVfHDw47edzA4eibalFDG',
  grant_type: 'password',
  recaptcha_site_key: '6Ld90wImAAAAADZlL8soUWrkdJCV6va62-uCMgNy',
  ga_measurement_id: 'G-TJ0C2JXR8D',
  replaysSampleRate: 0,
  tracesSampleRate: 0,

  api: 'https://merged.pap-isophi.doc.endevel.cz/parent/api/v1',
  parentApiBase: 'https://merged.pap-isophi.doc.endevel.cz/parent',
  authAPI: 'https://api.isophi-auth.doc.endevel.cz/api/v1',
  licenseAPI: 'https://merged.pap-isophi.doc.endevel.cz/license/api/v1',
  materialServer: 'https://merged.pap-isophi.doc.endevel.cz',
  contractServer: 'https://merged.pap-isophi.doc.endevel.cz',
  questionnaireApplicationUrl: 'https://dotaznik.isophi-pap.doc.endevel.cz',
  sentryDSN: 'https://7c28808b801e4ce1a2ca9d255e07aa55@o1002766.ingest.sentry.io/4505051932327936'
  /*
  api: 'https://merged.podporapedagoga.cz/parent/api/v1',
  parentApiBase: 'https://merged.podporapedagoga.cz/parent',
  authAPI: 'https://auth.isophi.education/api/v1',
  licenseAPI: 'https://license.podporapedagoga.cz/api/v1',
  materialServer: 'https://material.podporapedagoga.cz',
  contractServer: 'https://license.podporapedagoga.cz/',
  questionnaireApplicationUrl: 'https://dotaznik.podporapedagoga.cz',
  sentryDSN: 'https://7c28808b801e4ce1a2ca9d255e07aa55@o1002766.ingest.sentry.io/4505051932327936'
  /*
  api: 'https://merged.podporapedagoga.cz/parent/api/v1',
  parentApiBase: 'https://api.isophi-pap-parent.doc.endevel.cz',
  authAPI: 'https://auth.isophi.education/api/v1',
  licenseAPI: 'https://api.isophi-pap-merged.doc.endevel.cz/license/api/v1',
  materialServer: 'https://api.isophi-pap-material.doc.endevel.cz',
  contractServer: 'https://api.isophi-pap-merged.doc.endevel.cz/license',
  questionnaireApplicationUrl: 'https://isophi-pap-questionnaire-app.doc.endevel.cz',
  sentryDSN: 'https://7c28808b801e4ce1a2ca9d255e07aa55@o1002766.ingest.sentry.io/4505051932327936'
  */
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
